import React from "react";

import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Skills from "../components/Skills";
import Footer from "../components/Footer";

function Home() {
  return (
    <>
      <Navbar />
      <Header />
      <Skills />
      <Footer />
    </>
  );
}

export default Home;
