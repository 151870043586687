import React from "react";

import Navbar from "../components/Navbar";
import ContactMe from "../components/ContactMe";
import Footer from "../components/Footer";

function Contact() {
  return (
    <>
      <Navbar />
      <ContactMe />
      <Footer />
    </>
  );
}

export default Contact;
