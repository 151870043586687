import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaJava, FaPython, FaRocket, FaJsSquare } from "react-icons/fa";
import { ReactElement } from "react";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={8}
        h={8}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function Skills() {
  return (
    <Container py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform={"uppercase"}
            color={"purple.400"}
            fontWeight={600}
            fontSize={"sm"}
            bg={useColorModeValue("purple.50", "purple.800")}
            p={2}
            alignSelf={"flex-start"}
            rounded={"md"}
          >
            My Skills
          </Text>
          <Text color={"gray.500"} fontSize={"md"}>
            Over the last couple years I have worked with lots of languages and
            technologies.
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.200", "gray.600")}
              />
            }
          >
            <Feature
              icon={<Icon as={FaJava} color={"orange.500"} w={5} h={5} />}
              iconBg={useColorModeValue("gray.100", "gray.800")}
              text={"Java"}
            />
            <Feature
              icon={<Icon as={FaJsSquare} color={"yellow.500"} w={5} h={5} />}
              iconBg={useColorModeValue("gray.100", "gray.800")}
              text={"JavaScript"}
            />
            <Feature
              icon={<Icon as={FaPython} color={"blue.500"} w={5} h={5} />}
              iconBg={useColorModeValue("gray.100", "gray.800")}
              text={"Python"}
            />
            <Feature
              icon={<Icon as={FaRocket} color={"red.400"} w={5} h={5} />}
              iconBg={useColorModeValue("gray.100", "gray.800")}
              text={"and so much more..."}
            />
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded={"md"}
            alt={"feature image"}
            src={
              "https://cdn.discordapp.com/attachments/943506233476907038/944624455269904414/c56b81f790f11891f53d50bd15eac775.png"
            }
            objectFit={"cover"}
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
